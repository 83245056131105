<template>
  <div @click="drawerClick">
    <!-- <g-title>基本信息</g-title>
    <info-item :label-width="labelWidth" label="名称">{{ userInfo.nickName }}</info-item>
    <info-item :label-width="labelWidth" label="登录邮箱">{{ userInfo.email }}</info-item> -->

    <!-- <info-item :label-width="labelWidth" label="所属部门">
      <div class="flex flex-wrap pt-1">
        <a-tag v-for="(item, index) in userInfo.departs" :key="index" class="mr-3 mb-3">{{ item.departName }}</a-tag>
      </div>
    </info-item>
    <info-item :label-width="labelWidth" label="职位">
      <div class="flex flex-wrap pt-1">
        <a-tag v-for="(item, index) in userInfo.positions" :key="index" class="mr-3 mb-3">{{ item.name }}</a-tag>
      </div>
    </info-item>
    <info-item :label-width="labelWidth" label="角色">
      <div class="flex flex-wrap pt-1">
        <a-tag v-for="(item, index) in userInfo.roles" :key="index" class="mr-3 mb-3">{{ item.roleName }}</a-tag>
      </div>
    </info-item> -->
    <!-- <info-item label="所属公司">
        <div class="flex flex-wrap pt-1">
          <a-tag v-for="(item, index) in userInfo.companies" :key="index" class="mr-3 mb-3">{{ item.departName }}</a-tag>
        </div>
      </info-item> -->
    <a-form-model
      style="overflow:hidden"
      v-if="isFormShow"
      :model="formData.userFile"
      ref="dataForm"
      :rules="validatorRules"
      v-bind="layout"
    >
      <!-- <g-title>基本信息</g-title> -->
      <!-- <a-form-model-item label="昵称" prop="nickName">
        <a-input placeholder="请输入昵称" v-model="formData.nickName" />
      </a-form-model-item>
      <a-form-model-item label="登录邮箱" prop="email">
        <a-input placeholder="请输入登录邮箱" v-model="formData.email" :readOnly="!!formData.id" />
      </a-form-model-item>
      <template>
        <a-form-model-item label="登录密码" prop="password">
          <a-input type="password" v-model="formData.password" placeholder="请输入登录密码" />
        </a-form-model-item>

        <a-form-model-item label="确认密码" prop="confirmpassword">
          <a-input type="password" v-model="formData.confirmpassword" placeholder="请重新输入登录密码" />
        </a-form-model-item>
      </template> -->
      <!-- <a-form-model-item label="所属公司" prop="selectedCompany">
        <a-tree-select
          v-model="selectedCompanyIds"
          style="width: 100%"
          tree-checkable
          :tree-data="companyList"
          search-placeholder="选择所属公司"
          placeholder="请选择所属公司"
        />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="所属部门" prop="selectedDepart">
        <a-tree-select
          v-model="selectedDepartIds"
          style="width: 100%"
          tree-checkable
          :tree-data="departList"
          search-placeholder="选择所属部门"
          placeholder="请选择所属部门"
        />
      </a-form-model-item>
      <a-form-model-item label="职位">
        <a-select
          mode="multiple"
          style="width: 100%"
          placeholder="请选择职位"
          optionFilterProp="children"
          v-model="selectedPositionIds"
          :getPopupContainer="target => target.parentNode"
        >
          <a-select-option
            v-for="(position, positionIndex) in positionList"
            :key="positionIndex.toString()"
            :value="position.id"
          >
            {{ position.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="角色">
        <a-select
          mode="multiple"
          style="width: 100%"
          placeholder="请选择用户角色"
          optionFilterProp="children"
          v-model="selectedRoleIds"
          :getPopupContainer="target => target.parentNode"
        >
          <a-select-option v-for="(role, roleindex) in roleList" :key="roleindex.toString()" :value="role.id">
            {{ role.roleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-tabs :activeKey="tab" style="padding-top:20px;" @change="callback">
        <a-tab-pane key="1" tab="岗位信息">
          <a-form-model-item prop="avatar" label="头像" class="user-photo">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeCroUpload"
              @change="handleUpChange"
            >
              <div v-if="formData.userFile.avatar" class="img-div">
                <img ref="showImg" :src="formData.userFile.avatar" alt="avatar" class="upload_img" />
              </div>
              <div v-else>
                <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传头像
                </div>
              </div>
            </a-upload>
            <a-input
              allow-clear
              v-model="textMsg"
              @change="
                () => {
                  this.textMsg = ''
                }
              "
              placeholder="请粘贴图片或链接"
              @paste.native.capture.prevent="pasting"
            />
          </a-form-model-item>
          <a-form-model-item label="聊天工具" prop="qq" style="margin-bottom:0">
            <a-input @blur="qqChange" placeholder="请至少填写一种格式正确的账号" v-model="formData.userFile.qq">
              <a-icon
                slot="prefix"
                type="qq"
                style="width:1vw;position: relative;left: -5px;z-index:999;color:hsl(0deg 0% 75%);"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="qqType" ref="wechatDiv" class="qqType-item">
            <div slot="label" style="display:none"></div>
            <a-input @blur="qqChange" v-model="formData.userFile.qqType" placeholder="请至少填写一种格式正确的账号">
              <a-icon
                slot="prefix"
                type="wechat"
                style="width:1vw;position: relative;left: -5px;z-index:999;color:hsl(0deg 0% 75%);"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="contractType" label="签约类型">
            <a-select @change="contractTypeChange" placeholder="签约类型" v-model="formData.userFile.contractType">
              <a-select-option value="全职">全职</a-select-option>
              <a-select-option value="兼职">兼职</a-select-option>
              <a-select-option value="意向全职">意向全职</a-select-option>
              <a-select-option value="实习">实习</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="contractDate" label="签约日期">
            <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.contractDate" />
          </a-form-model-item>
          <!--           <a-form-model-item v-if="formData.userFile.contractType=='全职'" label="是否有试用期" prop="haveProbation">
            <a-radio-group v-model="formData.userFile.haveProbation">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="formData.userFile.haveProbation==1" label="试用期结束日期" prop="probationEndDate">
            <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.probationEndDate" :disabled="formData.userFile.contractType == '兼职'" />
          </a-form-model-item> -->
          <a-form-model-item
            label="是否有全职工作"
            v-show="formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'"
          >
            <a-radio-group v-model="formData.userFile.workStatus">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="workCity" label="工作地城市">
            <a-cascader
              :options="options"
              allowClear
              :defaultValue="formData.userFile.workCity"
              change-on-select
              :show-search="{ filter }"
              placeholder="请选择工作地城市"
              @change="onChange"
            />
          </a-form-model-item>
          <!--           <a-form-model-item label="每周可工作日">
            <a-select mode="multiple" placeholder="每周可工作日" v-model="formData.userFile.workDays" @change="workDaysChange">
              <a-select-option :value="1">周一</a-select-option>
              <a-select-option :value="2">周二</a-select-option>
              <a-select-option :value="3">周三</a-select-option>
              <a-select-option :value="4">周四</a-select-option>
              <a-select-option :value="5">周五</a-select-option>
              <a-select-option :value="6">周六</a-select-option>
              <a-select-option :value="7">周日</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="工作日每天可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.workdayAvailableCount" @change="workCountChange" />
          </a-form-model-item>
          <a-form-model-item label="周末可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.weekendAvailableCount" :disabled="dis" @change="workCountChange" />
          </a-form-model-item>
          <a-form-model-item label="当周可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.weekAvailableCount" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item label="当周已安排数量">
            <a-input-number :min="0" v-model="formData.userFile.weekAssignCount" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item label="当周空余数量">
            <a-input-number :min="0" v-model="formData.userFile.weekFreeCount" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item label="当月可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.monthAvailableCount" :disabled="true" />
          </a-form-model-item> -->
          <a-form-model-item v-if="typeShow" label="擅长作品类型">
            <div class="form-select" style="width:100%" @click.stop>
              <div style="height:40px;display: flex;align-items: center;">
                <div @click.self="isTagShow()" :class="isTag ? 'tag-select-show' : 'tag-select'">
                  <template
                    v-if="
                      formData.userFile.goodAtProductionType &&
                        formData.userFile.goodAtProductionType.length > 0 &&
                        isShow
                    "
                  >
                    <a-tag
                      closable
                      @close="tagClose(formValue, formData.userFile.goodAtProductionType)"
                      :key="formValue"
                      v-for="formValue in formData.userFile.goodAtProductionType"
                      >{{ formValue }}</a-tag
                    >
                  </template>
                  <template
                    v-else-if="
                      formData.userFile.goodAtProductionType &&
                        formData.userFile.goodAtProductionType.length > 0 &&
                        !isShow
                    "
                  >
                    <a-tag
                      closable
                      @close="tagClose(formValue, formData.userFile.goodAtProductionType)"
                      :key="formValue"
                      v-for="formValue in formData.userFile.goodAtProductionType.split(',')"
                      >{{ formValue }}</a-tag
                    >
                  </template>
                  <div class="tag-select-open " :style="isTag ? '' : 'height:0px;border:none'">
                    <template v-if="formData.userFile.goodAtProductionType">
                      <a-checkable-tag
                        :ref="option.value"
                        v-for="option in typeList"
                        :key="option.value"
                        v-model="option.checked"
                        :style="
                          typeof formData.userFile.goodAtProductionType != 'object'
                            ? formData.userFile.goodAtProductionType.split(',').length > 5
                              ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                              : ''
                            : formData.userFile.goodAtProductionType.length > 5
                            ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                            : ''
                        "
                        @change="tagChange(option.checked, option.value)"
                        >{{ option.value }}</a-checkable-tag
                      >
                    </template>
                    <template v-else>
                      <a-checkable-tag
                        :ref="option.value"
                        v-for="option in typeList"
                        :key="option.value"
                        v-model="option.checked"
                        @change="tagChange(option.checked, option.value)"
                        >{{ option.value }}</a-checkable-tag
                      >
                    </template>
                  </div>
                  <span class="ant-select-arrow">
                    <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="isTag ? 'up' : 'down'"></a-icon>
                  </span>
                </div>
              </div>
            </div>
            <!--             <a-select mode="multiple" placeholder="请选择" v-model="formData.userFile.goodAtProductionType" @change="getShow(formData.userFile.goodAtProductionType,'scShow')">
              <a-select-option :value="item" v-for="item in typeList" :key='item'>{{item}}</a-select-option>
            </a-select> -->
            <a-textarea
              v-if="scShow"
              autoSize
              v-model="formData.userFile.skilledProductOther"
              placeholder="请输入擅长的作品类型"
            />
          </a-form-model-item>
          <a-form-model-item  label="抵触作品类型">
            <!--             <a-select mode="multiple" placeholder="请选择" v-model="formData.userFile.badAtProductionType" @change="getShow(formData.userFile.badAtProductionType,'dcShow')">
              <a-select-option :value="item" v-for="item in typeList" :key='item'>{{item}}</a-select-option>
            </a-select> -->
            <div class="form-select" style="width:100%;height:120px;" @click.stop>
              <div style="height:40px;display: flex;align-items: center;">
                <div @click.self="isTagShowTwo()" :class="isTagTwo ? 'tag-select-show' : 'tag-select'">
                  <template
                    v-if="
                      formData.userFile.badAtProductionType &&
                        formData.userFile.badAtProductionType.length > 0 &&
                        isShow
                    "
                  >
                    <a-tag
                      closable
                      @close="tagCloseTwo(formValue, formData.userFile.badAtProductionType)"
                      :key="formValue"
                      v-for="formValue in formData.userFile.badAtProductionType"
                      >{{ formValue }}</a-tag
                    >
                  </template>
                  <template
                    v-else-if="
                      formData.userFile.badAtProductionType &&
                        formData.userFile.badAtProductionType.length > 0 &&
                        !isShow
                    "
                  >
                    <a-tag
                      closable
                      @close="tagCloseTwo(formValue, formData.userFile.badAtProductionType)"
                      :key="formValue"
                      v-for="formValue in formData.userFile.badAtProductionType.split(',')"
                      >{{ formValue }}</a-tag
                    >
                  </template>
                  <div class="tag-select-open " :style="isTagTwo ? '' : 'height:0px;border:none'">
                    <template v-if="formData.userFile.badAtProductionType">
                      <a-checkable-tag
                        :ref="option.value"
                        v-for="option in typeListTwo"
                        :key="option.value"
                        v-model="option.checked"
                        :style="
                          typeof formData.userFile.badAtProductionType != 'object'
                            ? formData.userFile.badAtProductionType.split(',').length > 5
                              ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                              : ''
                            : formData.userFile.badAtProductionType.length > 5
                            ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                            : ''
                        "
                        @change="tagChangeTwo(option.checked, option.value)"
                        >{{ option.value }}</a-checkable-tag
                      >
                    </template>
                    <template v-else>
                      <a-checkable-tag
                        :ref="option.value"
                        v-for="option in typeListTwo"
                        :key="option.value"
                        v-model="option.checked"
                        @change="tagChangeTwo(option.checked, option.value)"
                        >{{ option.value }}</a-checkable-tag
                      >
                    </template>
                  </div>
                  <span class="ant-select-arrow">
                    <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="isTagTwo ? 'up' : 'down'"></a-icon>
                  </span>
                </div>
              </div>
              <a-textarea
                v-if="dcShow"
                v-model="formData.userFile.unskilledProductOther"
                autoSize
                placeholder="请输入抵触的作品类型"
              />
            </div>
          </a-form-model-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="个人档案" force-render>
          <span v-if="tab == '2'" style="  position: absolute; ;font-size:12px;top:100px;color:#aca9a9"
            >下列信息仅BOSS及HR有权查看，请如实填写。</span
          >
          <a-form-model-item label="姓名">
            <a-input placeholder="请输入姓名" v-model="formData.userFile.userName" />
          </a-form-model-item>
          <a-form-model-item label="性别">
            <a-radio-group v-model="formData.userFile.sex">
              <a-radio :value="1">男</a-radio>
              <a-radio :value="0">女</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="出生日期">
            <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.bornDate" />
          </a-form-model-item>
          <a-form-model-item label="国籍">
            <a-select placeholder="国籍" v-model="formData.userFile.country">
              <a-select-option value="中国">中国</a-select-option>
              <a-select-option value="美国">美国</a-select-option>
              <a-select-option value="英国">英国</a-select-option>
              <a-select-option value="日本">日本</a-select-option>
              <a-select-option value="韩国">韩国</a-select-option>
            </a-select>
          </a-form-model-item>
<!--           <a-form-model-item v-if="formData.userFile.country == '中国'" label="民族">
            <a-auto-complete placeholder="民族" v-model="formData.userFile.nation">
              <template slot="dataSource">
                <a-select-option v-for="n in nationList" :key="n">{{ n }}</a-select-option>
              </template>
            </a-auto-complete>
          </a-form-model-item> -->
          <a-form-model-item label="电话号码">
            <a-input placeholder="请输入电话号码" v-model="formData.userFile.phone" />
          </a-form-model-item>
<!--           <a-form-model-item label="学历">
            <a-select placeholder="学历" v-model="formData.userFile.education">
              <a-select-option value="博士">博士</a-select-option>
              <a-select-option value="硕士">硕士</a-select-option>
              <a-select-option value="本科">本科</a-select-option>
              <a-select-option value="专科">专科</a-select-option>
              <a-select-option value="高中">高中</a-select-option>
              <a-select-option value="初中">初中</a-select-option>
              <a-select-option value="小学">小学</a-select-option>
            </a-select>
          </a-form-model-item> -->
          <!-- <a-form-model-item label="毕业院校">
            <a-input placeholder="请输入毕业院校" v-model="formData.userFile.school" />
          </a-form-model-item> -->
<!--           <a-form-model-item label="职位相关证书">
            <a-input placeholder="请输入职位相关证书" v-model="formData.userFile.certificate" />
          </a-form-model-item> -->
<!--           <a-form-model-item label="工作经历">
            <a-textarea
              autoSize
              placeholder="请输入和现在职位相关的工作经历"
              v-model="formData.userFile.workExperience"
            />
          </a-form-model-item> -->
<!--           <template
            v-if="
              (country == 'qt' || country == 'gat') &&
                formData.userFile.contractType != '意向全职' &&
                formData.userFile.contractType != '兼职'
            "
          >
            <div style="padding-top: 10px;padding-bottom: 15px;">
              <span style="display:inline-block;width:160px;font-weight:bold;font-size:16px;">英文银行账户信息</span>
              <span style="font-size:12px;color: rgba(0,0,0,0.25);">以下内容除收款账号，其余内容请输入英文信息</span>
            </div>
            <a-form-model-item label="户名">
              <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
            </a-form-model-item>
            <a-form-model-item label="收款账号">
              <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
            </a-form-model-item>
            <a-form-model-item label="银行名称">
              <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
            </a-form-model-item>
            <a-form-model-item label="支行名称">
              <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
            </a-form-model-item>
            <a-form-model-item label="SWIFT CODE">
              <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
            </a-form-model-item>
            <a-form-model-item label="银行地址">
              <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
            </a-form-model-item>
            <a-form-model-item label="个人地址">
              <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
            </a-form-model-item>
          </template>
          <template v-else>
            <a-form-model-item
              v-if="alipayAccountShow"
              label="收款账号"
              :prop="
                country != 'qt' &&
                country != 'gat' &&
                formData.userFile.contractType != '意向全职' &&
                formData.userFile.contractType != '兼职' &&
                formData.userFile.alipayAccount
                  ? 'alipayAccount'
                  : ''
              "
            >
              <a-select
                placeholder="请选择收款方式"
                v-if="formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'"
                @change="paymentTypeChange"
                :default-value="formData.userFile.paymentType"
              >
                <a-select-option value="EN_ACCOUNT">
                  <a-icon type="bank" style="width:1vw;position: relative;left: -3px" />
                  全英账户</a-select-option
                >
                <a-select-option value="ALIPAY">
                  <a-icon type="alipay-circle" style="width:1vw;position: relative;left: -3px;" />
                  支付宝</a-select-option
                >
                <a-select-option value="WECHAT">
                  <a-icon type="wechat" style="width:1vw;position: relative;left: -3px;" />
                  微信</a-select-option
                >
                <a-select-option
                  value="BANK"
                  v-if="
                    formData.userFile &&
                      formData.userFile.workCity &&
                      typeof formData.userFile.workCity === 'object' &&
                      formData.userFile.workCity.some(item => item == 83)
                  "
                >
                  <a-icon type="credit-card" style="width:1vw;position: relative;left: -3px;" />
                  银行账户</a-select-option
                >
              </a-select>
              <a-input
                v-if="
                  country != 'qt' &&
                    country != 'gat' &&
                    formData.userFile.contractType != '意向全职' &&
                    formData.userFile.contractType != '兼职'
                "
                placeholder="请输入招行卡号"
                v-model="formData.userFile.alipayAccount"
              >
                <img
                  slot="prefix"
                  src="./../../assets/zh.png"
                  alt=""
                  style="width:1vw;position: relative;left: -4px;"
                />
              </a-input>
              <a-input
                v-if="formData.userFile.paymentType == 'ALIPAY'"
                placeholder="请输入支付宝账号"
                v-model="formData.userFile.alipayAccount"
              >
              </a-input>
              <a-input
                v-if="formData.userFile.paymentType == 'WECHAT'"
                placeholder="请输入微信账号"
                v-model="formData.userFile.alipayAccount"
              >
              </a-input>
              <template
                v-if="
                  formData.userFile.paymentType == 'BANK' &&
                    formData.userFile.contractType != '全职' &&
                    formData.userFile.contractType != '实习'
                "
              >
                <a-select
                  show-search
                  placeholder="请选择开户行"
                  option-filter-prop="label"
                  v-if="country != 'qt' && country != 'gat'"
                  v-model="formData.userFile.bankName"
                >
                  <a-select-option :label="item.text" v-for="item in bankList" :key="item.value">{{
                    item.text
                  }}</a-select-option>
                </a-select>
                <a-input
                  v-if="country != 'qt' && country != 'gat'"
                  placeholder="请输入银行卡号"
                  @blur="submitBank"
                  v-model="formData.userFile.bankAccount"
                />
                <a-input
                  v-if="country != 'qt' && country != 'gat'"
                  placeholder="请输入户名"
                  v-model="formData.userFile.paymentName"
                />
              </template>
            </a-form-model-item>
          </template>
          <template
            v-if="
              formData.userFile.paymentType == 'BANK' &&
                formData.userFile.contractType != '全职' &&
                formData.userFile.contractType != '实习' &&
                country == 'qt'
            "
          >
            <a-form-model-item label="名前片假名">
              <a-input placeholder="请输入名前片假名" v-model="formData.userFile.paymentName" />
            </a-form-model-item>
            <a-form-model-item label="銀行名">
              <a-input placeholder="请输入銀行名" v-model="formData.userFile.bankName" />
            </a-form-model-item>
            <a-form-model-item label="店番号">
              <a-input placeholder="请输入店番号" v-model="formData.userFile.bankAccount" />
            </a-form-model-item>
            <a-form-model-item label="支店名">
              <a-input placeholder="请输入支店名" v-model="formData.userFile.branchBank" />
            </a-form-model-item>
            <a-form-model-item label="口座番号">
              <a-input placeholder="请输入口座番号" v-model="formData.userFile.bankAddress" />
            </a-form-model-item>
            <a-form-model-item label="預金種別">
              <a-input placeholder="请输入預金種別" v-model="formData.userFile.personalAddress" />
            </a-form-model-item>
          </template> -->
<!--           <span style="color:red;font-size:12px;position:relative;top:-3px;"
            >*本人承诺以上所填信息真实有效，若有虚假，本人自愿承担带来的经济损失</span
          > -->
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
  </div>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { duplicateCheck } from '@/api/api'
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { putAction } from '@/api/manage'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { citys } from './city.js'
/* import userCropperModel from './cropper-model.vue' */
import axios from 'axios'
export default {
  name: 'loginUserModal',
  mixins: [DrawerMixins, UploadFileToOssMixins],
  components: {},
  props: {
    tab: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      textMsg: '',
      options: [],
      typeShow: true,
      dis: true,
      scShow: false,
      dcShow: false,
      modalWidth: 800,
      drawerWidth: 700,
      userInfo: null,
      avatarLoading: false,
      labelWidth: '160',
      userId: '', //保存用户id
      disableSubmit: false,
      dateFormat: 'YYYY-MM-DD',
      fileList: [],
      alipayAccountShow: true,
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 17
        }
      },
      isFormShow: true,
      validatorRules: {
        avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        bornDate: [{ required: true, message: '请选择出生日期', trigger: 'blur' }],
        country: [{ required: true, message: '请选择国籍', trigger: 'blur' }],
        nation: [{ required: true, message: '请选择民族', trigger: 'blur' }],
        idCardNo: [{ required: true, message: '请输入身份证号/护照号', trigger: 'blur' }],
        qq: [
          { required: true, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ],
        qqType: [
          {
            required: true,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ],
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        education: [{ required: true, message: '请选择您的学历', trigger: 'blur' }],
        school: [{ required: true, message: '请输入学校', trigger: 'blur' }],
        certificate: [{ required: true, message: '请输入职位相关证书', trigger: 'blur' }],
        workExperience: [{ required: true, message: '请输入和现在职位相关的工作经历', trigger: 'blur' }],
        workCity: [{ required: true, message: '请输入工作地城市', trigger: 'blur' }],
        contractType: [{ required: true, message: '请选择签约类型', trigger: 'blur' }],
        contractDate: [{ required: true, message: '请选择签约日期', trigger: 'change' }],
        email: [{ validator: this.validateEmail }],
        password: [{ validator: this.validateToNextPassword }],
        confirmpassword: [{ validator: this.compareToFirstPassword, trigger: 'blur' }],
        haveProbation: [{ validator: this.validateProbation }],
        probationEndDate: [{ validator: this.validateProbationEndDate }],
        workStatus: [{ validator: this.validateWorkStatus }],
        workdayAvailableCount: [{ required: true, message: '请填写工作日每天可安排数量', trigger: 'blur' }],
        weekendAvailableCount: [{ required: true, message: '请填写周末两天可安排数量', trigger: 'blur' }],
        weekAvailableCount: [{ required: true, message: '请填写周可安排数量', trigger: 'blur' }],
        weekAssignCount: [{ required: true, message: '请填写周已安排数量', trigger: 'blur' }],
        weekFreeCount: [{ required: true, message: '请填写周空余数量', trigger: 'blur' }],
        monthAvailableCount: [{ required: false, message: '请填写月总数量', trigger: 'blur' }],
        alipayAccount: [
          {
            required: true,
            message: '银行卡号格式错误，请重新输入',
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
            trigger: 'blur'
          }
        ],
        alipayOtherAccount: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
        goodAtProductionType: [{ required: true, message: '请输入擅长的作品类型', trigger: 'blur' }],
        badAtProductionType: [{ required: true, message: '请输入抵触的作品类型', trigger: 'blur' }]
      },
      title: '操作',
      visible: false,
      formData: { userFile: { avatar: '', paymentType: undefined } },
      oldFormData: {},
      selectedCompanyIds: [],
      selectedDepartIds: [],
      selectedPositionIds: [],
      selectedRoleIds: [],
      uploadLoading: false,
      confirmLoading: false,
      isShow: true,
      headers: {},
      nationList: [
        '汉族',
        '满族',
        '蒙古族',
        '回族',
        '藏族',
        '维吾尔族',
        '苗族',
        '彝族',
        '壮族',
        '布依族',
        '侗族',
        '瑶族',
        '白族',
        '土家族',
        '哈尼族',
        '哈萨克族',
        '傣族',
        '黎族',
        '傈僳族',
        '佤族',
        '畲族',
        '高山族',
        '拉祜族',
        '水族',
        '东乡族',
        '纳西族',
        '景颇族',
        '柯尔克孜族',
        '土族',
        '达斡尔族',
        '仫佬族',
        '羌族',
        '布朗族',
        '撒拉族',
        '毛南族',
        '仡佬族',
        '锡伯族',
        '阿昌族',
        '普米族',
        '朝鲜族',
        '塔吉克族',
        '怒族',
        '乌孜别克族',
        '俄罗斯族',
        '鄂温克族',
        '德昂族',
        '保安族',
        '裕固族',
        '京族',
        '塔塔尔族',
        '独龙族',
        '鄂伦春族',
        '赫哲族',
        '门巴族',
        '珞巴族',
        '基诺族'
      ],
      url: {
        edit: '/sys/user/edit_user_file',
        add: '/sys/user/add_mx'
      },
      onlyjf: [],
      canUpload: false,
      isTag: false,
      isTagTwo: false,
      country: '',
      bankList: [],
      typeList: [
        { value: '门派', checked: false },
        { value: '武侠', checked: false },
        { value: '玄幻', checked: false },
        { value: '奇幻', checked: false },
        { value: '异能', checked: false },
        { value: '仙侠', checked: false },
        { value: '复仇', checked: false },
        { value: '动作', checked: false },
        { value: '恋爱', checked: false },
        { value: '霸凌', checked: false },
        { value: '惊悚', checked: false },
        { value: '丧尸', checked: false },
        { value: '竞技', checked: false },
        { value: '冒险', checked: false },
        { value: 'AI', checked: false },
        { value: '搞笑', checked: false },
        { value: '豪门霸总', checked: false },
        { value: '都市', checked: false },
        { value: '契约', checked: false },
        { value: '养成', checked: false },
        { value: '治愈', checked: false },
        { value: '悬疑', checked: false },
        { value: '料理', checked: false },
        { value: '妖怪', checked: false },
        { value: '精灵', checked: false },
        { value: '医学', checked: false },
        { value: '科幻', checked: false },
        { value: '军事', checked: false },
        { value: '同人', checked: false },
        { value: '热血', checked: false },
        { value: '暗黑', checked: false },
        { value: '美食', checked: false },
        { value: '逆袭', checked: false },
        { value: '励志', checked: false },
        { value: '伦理', checked: false },
        { value: '性转', checked: false },
        { value: '宫斗', checked: false },
        { value: '烧脑', checked: false },
        { value: '其他', checked: false }
      ],
      typeListTwo: [
        { value: '门派', checked: false },
        { value: '武侠', checked: false },
        { value: '玄幻', checked: false },
        { value: '奇幻', checked: false },
        { value: '异能', checked: false },
        { value: '仙侠', checked: false },
        { value: '复仇', checked: false },
        { value: '动作', checked: false },
        { value: '恋爱', checked: false },
        { value: '霸凌', checked: false },
        { value: '惊悚', checked: false },
        { value: '丧尸', checked: false },
        { value: '竞技', checked: false },
        { value: '冒险', checked: false },
        { value: 'AI', checked: false },
        { value: '搞笑', checked: false },
        { value: '豪门霸总', checked: false },
        { value: '都市', checked: false },
        { value: '契约', checked: false },
        { value: '养成', checked: false },
        { value: '治愈', checked: false },
        { value: '悬疑', checked: false },
        { value: '料理', checked: false },
        { value: '妖怪', checked: false },
        { value: '精灵', checked: false },
        { value: '医学', checked: false },
        { value: '科幻', checked: false },
        { value: '军事', checked: false },
        { value: '同人', checked: false },
        { value: '热血', checked: false },
        { value: '暗黑', checked: false },
        { value: '美食', checked: false },
        { value: '逆袭', checked: false },
        { value: '励志', checked: false },
        { value: '伦理', checked: false },
        { value: '性转', checked: false },
        { value: '宫斗', checked: false },
        { value: '烧脑', checked: false },
        { value: '其他', checked: false }
      ]
    }
  },
  created() {
    const token = Vue.ls.get(ACCESS_TOKEN)
    this.headers = { 'X-Access-Token': token }
    this.userInfo = Vue.ls.get(USER_INFO)
    this.getPosition()
    this.getDepartList()
    this.getRoles()
    this.options = citys.options
    this.bankList = citys.bankList
    const { nickName, email, departs, roles, positions, id, userFile } = this.userInfo
    this.formData = {
      ...this.formData,
      userFile,
      id,
      nickName,
      email,
      departs,
      roles,
      positions
    }
    if (this.formData.userFile) {
      if (this.formData.userFile.workCity && typeof this.formData.userFile.workCity != 'object') {
        this.formData.userFile.workCity = this.formData.userFile.workCity.split(',')
        this.country = this.formData.userFile.workCity[0]
      }
      if (this.formData.userFile.badAtProductionType && typeof this.formData.userFile.badAtProductionType != 'object') {
        this.formData.userFile.badAtProductionType = this.formData.userFile.badAtProductionType.split(',')
        this.formData.userFile.badAtProductionType.forEach(item => {
          this.typeListTwo.forEach(type => {
            if (type.value == item) {
              type.checked = true
            }
          })
          if (item == '其他') {
            this.dcShow = true
          }
        })
      }
      if (
        this.formData.userFile.goodAtProductionType &&
        typeof this.formData.userFile.goodAtProductionType != 'object'
      ) {
        this.formData.userFile.goodAtProductionType = this.formData.userFile.goodAtProductionType.split(',')
        this.formData.userFile.goodAtProductionType.forEach(item => {
          this.typeList.forEach(type => {
            if (type.value == item) {
              type.checked = true
            }
          })
          if (item == '其他') {
            this.scShow = true
          }
        })
      }
      if (!this.formData.userFile.workDays) this.formData.userFile.workDays = []
    }
    this.oldFormData = JSON.parse(JSON.stringify(this.formData))
    this.typeShow = true
    this.formData.roles.forEach(item => {
      if (item.roleCode == 'admin' || item.roleCode == 'boss' || item.roleCode == 'tongchou' || item.roleCode == 'hr') {
        this.typeShow = false
      }
    })
  },
  computed: {
    uploadAction: function() {
      return this.url.fileUpload
    },
    css() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  methods: {
    alipayAccountBlur(e) {
      let reg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
      if (reg.test(e.target.value.replace(/\s*/g, ''))) {
        this.validatorRules.alipayAccount = [
          {
            required: false,
            trigger: 'blur'
          }
        ]
      } else {
        this.validatorRules.alipayAccount = [
          {
            required: true,
            message: '银行卡号格式错误，请重新输入',
            trigger: 'blur',
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
          }
        ]
      }
      this.$forceUpdate()
    },
    qqChange() {
      let regQQ = /^[1-9][0-9]{4,9}$/
      let regWX = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
      if (regQQ.test(this.formData.userFile.qq) || regWX.test(this.formData.userFile.qqType)) {
        this.validatorRules.qq = [
          { required: false, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ]
        this.validatorRules.qqType = [
          {
            required: false,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ]
        this.isFormShow = false
        this.$nextTick(() => {
          this.isFormShow = true
        })
        setTimeout(() => {
          this.$refs.wechatDiv.$el.childNodes[0].childNodes[0].style.display = 'none'
        }, 0)
      } else if (!this.formData.userFile.qq && !this.formData.userFile.qqType) {
        this.validatorRules.qq = [
          { required: true, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ]
        this.validatorRules.qqType = [
          {
            required: true,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ]
      }
    },
    drawerClick() {
      this.isTag = false
      this.isTagTwo = false
    },
    getBank(name) {
      let text = ''
      citys.bankList.forEach(item => {
        if (item.value == name) {
          text = item.text
          return
        }
      })
      return text
    },
    getWorkCity(data) {
      let text = ''
      if (data) {
        citys.options.forEach(item => {
          if (item.value == data[data.length - 1]) {
            text = item.label
            return text
          }
          item.children?.forEach(i => {
            if (i.value == data[data.length - 1]) {
              text = i.label
              return text
            }
            i.children?.forEach(e => {
              if (e.value == data[data.length - 1]) {
                text = i.label + '/' + e.label
                return text
              }
            })
          })
        })
      }
      return text
    },
    contractTypeChange() {
      this.formData.userFile.bankAccount = ''
      this.formData.userFile.paymentName = ''
      this.formData.userFile.bankName = ''
      this.formData.userFile.branchBank = ''
      this.formData.userFile.paymentBank = ''
      this.formData.userFile.bankAddress = ''
      this.formData.userFile.personalAddress = ''
      this.formData.userFile.paymentType = null
      this.formData.userFile.alipayAccount = ''
      this.formData.userFile.paymentName = ''
    },
    paymentTypeChange(value) {
      this.formData.userFile.paymentType = value
      this.formData.userFile.alipayOtherAccount = ''
      this.formData.userFile.alipayAccount = ''
      this.$forceUpdate()
    },
    submitBank() {
      let str = this.formData.userFile.bankAccount.replace(/\s*/g, '')
      axios
        .get(
          `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${str}&cardBinCheck=true`
        )
        .then(res => {
          const { bank, cardType, validated, messages } = res.data
          if (validated) {
            this.formData.userFile.bankName = bank
          }
        })
    },
    tagChangeTwo(e, option) {
      if (!this.formData.userFile.badAtProductionType) this.formData.userFile.badAtProductionType = []
      if (e) {
        if (typeof this.formData.userFile.badAtProductionType == 'object') {
          this.formData.userFile.badAtProductionType.push(option)
        } else {
          this.formData.userFile.badAtProductionType = this.formData.userFile.badAtProductionType + ',' + option
        }
      } else {
        let deleteIndex = this.formData.userFile.badAtProductionType
          ?.map(item => {
            return item
          })
          .indexOf(option)
        if (!this.formData.userFile.badAtProductionType) {
          this.formData.userFile.badAtProductionType = []
        } else {
          this.formData.userFile.badAtProductionType.splice(deleteIndex, 1)
        }
      }
      this.getShow(this.formData.userFile.badAtProductionType, 'dcShow')
    },
    tagChange(e, option) {
      if (!this.formData.userFile.goodAtProductionType) this.formData.userFile.goodAtProductionType = []
      if (e) {
        if (typeof this.formData.userFile.goodAtProductionType == 'object') {
          this.formData.userFile.goodAtProductionType.push(option)
        } else {
          this.formData.userFile.goodAtProductionType = this.formData.userFile.goodAtProductionType + ',' + option
        }
      } else {
        if (typeof this.formData.userFile.goodAtProductionType != 'object') {
          this.formData.userFile.goodAtProductionType = this.formData.userFile.goodAtProductionType.split(',')
        }
        let deleteIndex = this.formData.userFile.goodAtProductionType
          ?.map(item => {
            return item
          })
          .indexOf(option)
        if (!this.formData.userFile.goodAtProductionType) {
          this.formData.userFile.goodAtProductionType = []
        } else {
          this.formData.userFile.goodAtProductionType.splice(deleteIndex, 1)
        }
      }
      this.getShow(this.formData.userFile.goodAtProductionType, 'scShow')
    },
    tagClose(formValue, formValues) {
      if (formValue == '其他') {
        this.formData.userFile.skilledProductOther = ''
        this.scShow = false
      }
      if (typeof formValues != 'object') {
        let arr = formValues.split(',')
        this.$nextTick(() => {
          this.typeList[
            this.typeList
              .map(item => {
                return item.value
              })
              .indexOf(formValue)
          ].checked = false
          let deleteIndex = arr
            .map(item => {
              return item
            })
            .indexOf(formValue)
          arr.splice(deleteIndex, 1)
          formValues = arr.join(',')
          this.formData.userFile.goodAtProductionType = formValues
        })
      } else {
        this.$nextTick(() => {
          this.typeList[
            this.typeList
              .map(item => {
                return item.value
              })
              .indexOf(formValue)
          ].checked = false
          let deleteIndex = formValues
            .map(item => {
              return item
            })
            .indexOf(formValue)
          formValues.splice(deleteIndex, 1)
        })
      }
    },
    tagCloseTwo(formValue, formValues) {
      if (formValue == '其他') {
        this.formData.userFile.unskilledProductOther = ''
        this.dcShow = false
      }
      if (typeof formValues != 'object') {
        let arr = formValues.split(',')
        this.$nextTick(() => {
          this.typeListTwo[
            this.typeListTwo
              .map(item => {
                return item.value
              })
              .indexOf(formValue)
          ].checked = false
          let deleteIndex = arr
            .map(item => {
              return item
            })
            .indexOf(formValue)
          arr.splice(deleteIndex, 1)
          formValues = arr.join(',')
          this.formData.userFile.badAtProductionType = formValues
        })
      } else {
        this.$nextTick(() => {
          this.typeListTwo[
            this.typeListTwo
              .map(item => {
                return item.value
              })
              .indexOf(formValue)
          ].checked = false
          let deleteIndex = formValues
            .map(item => {
              return item
            })
            .indexOf(formValue)
          formValues.splice(deleteIndex, 1)
        })
      }
    },
    isTagShow() {
      this.isTag = !this.isTag
      this.isTagTwo = false
    },
    isTagShowTwo() {
      this.isTagTwo = !this.isTagTwo
      this.isTag = false
    },
    onChange(value) {
      this.country = value[0] || ''
      this.formData.userFile.workCity = value
      this.formData.userFile.paymentType = undefined
    },
    filter(inputValue, path) {
      console.log(path)
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    getShow(list, type) {
      if (list) {
        if (list.length > 0) {
          if (
            list.some(item => {
              return item == '其他'
            })
          ) {
            if (type == 'scShow') {
              this.scShow = true
            } else {
              this.dcShow = true
            }
          } else {
            if (type == 'scShow') {
              this.scShow = false
            } else {
              this.dcShow = false
            }
          }
        } else {
          if (type == 'scShow') {
            this.scShow = false
          } else {
            this.dcShow = false
          }
        }
      } else {
        if (type == 'scShow') {
          this.scShow = false
        } else {
          this.dcShow = false
        }
      }
    },
    callback(key) {
      this.$emit('changeTab', key == '1')
    },
    handleUpChange({ file, fileList }) {
      return false
    },
    beforeCroUpload(file) {
      this.fileList = [...this.fileList, file]
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      /* this.formData.userFile.avatar = url */
      this.textMsg = ''
      this.$emit('openCropper', url)
      return false
    },
    async pasting(event) {
      let txt = event.clipboardData.getData('Text')
      if (typeof txt == 'string') {
        this.textMsg += txt
        /* this.formData.userFile.avatar = txt */
        this.$emit('openCropper', txt)
      }
      let file = null
      const items = (event.clipboardData || window.clipboardData).items
      if (items.length) {
        this.avatarLoading = true
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile()
            this.handleChange(file)
            if (!this.canUpload) {
              this.canUpload = !this.canUpload
            }
            break
          }
        }
      }
    },
    async upImg(file) {
      await this.beforeUpload(file)
      const token = Vue.ls.get(ACCESS_TOKEN)
      let formData = new FormData()
      this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      axios
        .post(this.ossUploadUrl, formData, {
          'Content-Type': 'multipart/form-data;',
          headers: {
            'X-Access-Token': token
          }
        })
        .then(() => {
          file.status = 'done'
          this.handleChangeUpload({ file })
        })
    },
    handleChange(file, filelist) {
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      /* this.formData.userFile.avatar = url */
      this.$emit('openCropper', url)
      this.textMsg = ''
      return false
    },
    initForm() {
      this.selectedCompanyIds = []
      this.selectedDepartIds = []
      this.selectedPositionIds = []
      this.selectedRoleIds = []
      this.formData = {
        nickName: '',
        email: '',
        companies: [],
        departs: [],
        roles: [],
        positions: [],
        userFile: {}
      }
      this.typeListTwo.forEach(item => {
        item.checked = false
      })
      this.typeList.forEach(item => {
        item.checked = false
      })
    },

    saveForm(callback, isChangeTab) {
      let regQQ = /^[1-9][0-9]{4,9}$/
      let regWX = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/
      if (regQQ.test(this.formData.userFile.qq) || regWX.test(this.formData.userFile.qqType)) {
        this.validatorRules.qq = [
          { required: false, pattern: /^[1-9][0-9]{4,9}$/, message: '请至少填写一种格式正确的账号', trigger: 'blur' }
        ]
        this.validatorRules.qqType = [
          {
            required: false,
            pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
            message: '请至少填写一种格式正确的账号',
            trigger: 'blur'
          }
        ]
      }
      if (this.tab == '1') {
        this.validatorRules.alipayAccount = [
          {
            required: false,
            trigger: 'blur'
          }
        ]
      } else {
        this.validatorRules.alipayAccount = [
          {
            required: true,
            message: '银行卡号格式错误，请重新输入',
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,
            trigger: 'blur'
          }
        ]
        this.alipayAccountShow = false
        this.$nextTick(() => {
          this.alipayAccountShow = true
        })
      }
      setTimeout(() => {
        this.$refs['dataForm'].validate(async valid => {
          if (valid) {
            let oldData = JSON.stringify(this.oldFormData)
            let newData = JSON.stringify(this.formData)
            if (oldData == newData) {
              if (this.tab == '2' && isChangeTab) {
                this.$emit('changeTabs', '1')
                return
              } else if (this.tab == '1' && isChangeTab) {
                this.$emit('changeTabs', '2')
                return
              }
            }
            //修改一下form的数据
            this.formData.selectedCompanyIds = this.selectedCompanyIds ? this.selectedCompanyIds.join(',') : null
            this.formData.selectedDepartIds = this.selectedDepartIds ? this.selectedDepartIds.join(',') : null
            this.formData.selectedPositionIds = this.selectedDepartIds ? this.selectedPositionIds.join(',') : null
            this.formData.selectedRoleIds = this.selectedRoleIds ? this.selectedRoleIds.join(',') : null
            if (this.formData.id) {
              this.formData.userFile.userId = this.formData.id
            }
            if (this.formData.userFile.bornDate && this.formData.userFile.bornDate.format) {
              this.formData.userFile.bornDate = this.formData.userFile.bornDate.format(this.dateFormat)
            }
            if (this.formData.userFile.contractDate && this.formData.userFile.contractDate.format) {
              this.formData.userFile.contractDate = this.formData.userFile.contractDate.format(this.dateFormat)
            }
            if (this.formData.userFile.probationEndDate && this.formData.userFile.probationEndDate.format) {
              this.formData.userFile.probationEndDate = this.formData.userFile.probationEndDate.format(this.dateFormat)
            }
            if (this.formData.userFile.workCity && typeof this.formData.userFile.workCity == 'object') {
              this.formData.userFile.workCity = this.formData.userFile.workCity.join(',')
            }
            if (
              this.formData.userFile.goodAtProductionType &&
              typeof this.formData.userFile.goodAtProductionType == 'object'
            ) {
              this.formData.userFile.goodAtProductionType = this.formData.userFile.goodAtProductionType.join(',')
            }
            if (
              this.formData.userFile.badAtProductionType &&
              typeof this.formData.userFile.badAtProductionType == 'object'
            ) {
              this.formData.userFile.badAtProductionType = this.formData.userFile.badAtProductionType.join(',')
            }
            this.isShow = false
            this.formData.firstLogin = false
            let workInfoTimer = true
            if (!this.formData.userFile.userName) workInfoTimer = false
            if (!this.formData.userFile.sex) workInfoTimer = false
            if (!this.formData.userFile.bornDate) workInfoTimer = false
            if (!this.formData.userFile.country) workInfoTimer = false
            if (!this.formData.userFile.nation) workInfoTimer = false
            if (!this.formData.userFile.phone) workInfoTimer = false
            if (!this.formData.userFile.education) workInfoTimer = false
            if (!this.formData.userFile.certificate) workInfoTimer = false
            if (!this.formData.userFile.school) workInfoTimer = false
            if (!this.formData.userFile.workExperience) workInfoTimer = false
            if (
              (this.country == 'qt' || this.country == 'gat') &&
              this.formData.userFile.contractType != '意向全职' &&
              this.formData.userFile.contractType != '兼职'
            ) {
              if (!this.formData.userFile.bankAccount) workInfoTimer = false
              if (!this.formData.userFile.paymentName) workInfoTimer = false
              if (!this.formData.userFile.bankName) workInfoTimer = false
              if (!this.formData.userFile.branchBank) workInfoTimer = false
              if (!this.formData.userFile.paymentBank) workInfoTimer = false
              if (!this.formData.userFile.bankAddress) workInfoTimer = false
              if (!this.formData.userFile.personalAddress) workInfoTimer = false
            }
            if (
              this.country != 'qt' &&
              this.country != 'gat' &&
              this.formData.userFile.contractType != '意向全职' &&
              this.formData.userFile.contractType != '兼职'
            ) {
              if (!this.formData.userFile.alipayAccount) {
                workInfoTimer = false
              } else {
                this.formData.userFile.alipayAccount = this.formData.userFile.alipayAccount.replace(/\s*/g, '')
              }
            }
            if (!workInfoTimer) {
              workInfoTimer = this.getNowDate()
            } else {
              workInfoTimer = null
            }
            delete this.formData.userFile.needUserGuide
            delete this.formData.userFile.workInfoTimer
            putAction(this.url.edit, this.formData).then(res => {
              if (res.success) {
                if (this.formData.id == this.userInfo.id) {
                  this.userInfo.userFile = this.formData.userFile
                  Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
                }
                this.$message.success(res.message)
                this.oldFormData = JSON.parse(JSON.stringify(this.formData))
                this.$emit('changeTab', this.tab == '1')
                if (this.tab == '2' && isChangeTab) {
                  this.$emit('changeTabs', '1')
                } else if (this.tab == '1' && isChangeTab) {
                  this.$emit('changeTabs', '2')
                } else if (this.tab == '2' && !isChangeTab) {
                  this.$emit('closeUserModal')
                } else if (this.tab == '1' && !isChangeTab) {
                  this.$emit('changeTabs', '2')
                }
                callback?.()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        })
      })
    },
    getNowDate() {
      var date = new Date()
      var sign2 = ':'
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1 // 月
      var day = date.getDate() // 日
      var hour = date.getHours() // 时
      var minutes = date.getMinutes() // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天']
      var week = weekArr[date.getDay()]
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      if (hour >= 0 && hour <= 9) {
        hour = '0' + hour
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = '0' + minutes
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = '0' + seconds
      }
      return year + '-' + month + '-' + day + ' ' + hour + sign2 + minutes + sign2 + seconds
    },
    setFormData() {},
    moment,
    validateToNextPassword(rule, value, callback) {
      const confirmpassword = this.formData.confirmpassword

      if (value && confirmpassword && value !== confirmpassword) {
        callback('两次输入的密码不一样！')
      }
      callback()
    },
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.formData.password) {
        callback('两次输入的密码不一样！')
      } else {
        callback()
      }
    },
    validateEmail(rule, value, callback) {
      if (this.isEdit) {
        callback()
        return
      }
      if (!value) {
        callback()
      } else {
        if (
          new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          var params = {
            tableName: 'sys_user',
            fieldName: 'email',
            fieldVal: value,
            dataId: this.userId
          }
          duplicateCheck(params).then(res => {
            console.log(res)
            if (res.success) {
              callback()
            } else {
              callback('邮箱已存在!')
            }
          })
        } else {
          callback('请输入正确格式的邮箱!')
        }
      }
    },
    validateProbation(rule, value, callback) {
      if (this.formData.userFile.contractType == '全职') {
        if (this.formData.userFile.haveProbation != '1') {
          callback('签约全职时必须选择试用期')
          return
        }
      }
      callback()
    },
    validateProbationEndDate(rule, value, callback) {
      if (this.formData.userFile.haveProbation == '1') {
        if (!this.formData.userFile.probationEndDate) {
          callback('请选择试用期结束时间')
          return
        }
      }
      callback()
    },
    validateWorkStatus(rule, value, callback) {
      if (this.formData.userFile.contractType == '兼职') {
        if (!this.formData.userFile.probationEndDate) {
          callback('签约兼职时必须选择是否有全职工作')
          return
        }
      }
      callback()
    },
    handleChangeUpload(info, index) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.userFile.avatar = params.filePath
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
        this.textMsg = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .qqType-item .ant-form-item-required::after {
  display: none !important;
}
/deep/ .qqType-item .ant-form-item-required::before {
  display: none !important;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload_img {
  max-width: 120px;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
/deep/ .qq-select {
  .ant-select-arrow {
    display: none !important;
  }
}
/deep/ .ant-drawer-body {
  padding-right: 10px !important;
}
/deep/ .ant-tabs-bar {
  margin-bottom: 8vh !important;
}
/deep/ .pb-14 {
  padding-bottom: 0 !important;
}
/deep/ .user-photo {
  .ant-form-item-children {
    display: flex !important;
    align-items: center;
    .avatar-uploader {
      padding-right: 20px;
      width: auto !important;
    }
  }
}
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}

.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}

/deep/ .avatar-uploader > .ant-upload {
  width: 122px !important;
  height: 122px !important;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.img-div {
  width: 120px;
  height: 120px;
  border-radius: 50% !important;
  overflow: hidden;
}
.upload_img {
  width: auto;
  height: 100%;
}
/deep/ .ant-tabs-tab {
  font-size: 1rem !important;
  font-weight: 500;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
  border-radius: 50% !important;
}
/deep/ .ant-upload.ant-upload-select-picture-card:hover {
  border-color: var(--theme-color) !important;
}
/deep/ .ant-upload {
  padding: 0 !important;
}
.tag-select {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid rgb(124, 170, 235);
  }
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
}
.tag-select-show {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  box-shadow: 0 0 0 1.5px rgb(124, 170, 235);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
  .ant-tag-checkable-checked {
    background-color: #5389df;
    color: #fff;
    cursor: pointer;
  }
}
/deep/ .tag-select-open {
  position: absolute;
  border: 1px solid #d9d9d9;
  width: 428px;
  top: 36px;
  display: flex;
  left: -2px;
  height: 96px;
  overflow-y: overlay;
  flex-wrap: wrap;
  background-color: #fff;
  padding-left: 7px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(235, 233, 233);
  transition: 0.2s;
  align-content: flex-start;
  z-index: 1000;
  .ant-tag {
    height: 25px;
    margin-top: 5px;
    line-height: 23px;
    &:hover {
      cursor: pointer;
    }
  }
}
.tag-select-close {
  position: absolute;
  display: none;
  transition: 0.2s;
  width: 428px;
  top: 30px;
  left: -2px;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px black;
}
</style>
